import { useAuth } from "context/AuthContext";
import { roles } from "context/Permissions/RolesPermissions";
import { useEffect, useState } from 'react';
import {
    Flex,
    Text,
    Avatar,
    Spinner,
} from "@chakra-ui/react";

const WelcomeUser = () => {
    const { fetchUserDetails, userDetails, token } = useAuth();
    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        if (token && !hasFetched) {
            fetchUserDetails(token).finally(() => setHasFetched(true));
        }
    }, [token, hasFetched, fetchUserDetails]);

    if (!userDetails) {
        return (
            <Flex alignItems="center">
                <Spinner size="sm" />
            </Flex>
        );
    }

    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    let greeting = "Bonjour";
    if (currentHour >= 18) {
        greeting = "Bonsoir";
    }

    // Fonction pour obtenir le nom du rôle en fonction du grade
    const getRoleNameByGrade = (grade) => {
        return Object.keys(roles).find(role => roles[role].grade === grade) || 'Inconnu';
    };

    const userGrade = userDetails.grade; // Assurez-vous que grade est bien présent dans userDetails
    const userRoleName = getRoleNameByGrade(userGrade);

    return (
        <Flex alignItems="center">
            <Text mr="2">{`${greeting}, ${userDetails.firstname} ${userDetails.lastname}`}</Text>
            <Text mr="2">{`Grade : ${userRoleName}`}</Text>
        </Flex>
    );
};
  
export default WelcomeUser;