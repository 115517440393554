import React, { useState, useEffect } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import UserList from './UserList';
import Messages from './Messages';

function DMApp() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    // Charger la liste des utilisateurs
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('http://mariabird.net:65535/api/dms');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    // Charger les messages pour l'utilisateur sélectionné
    useEffect(() => {
        if (selectedUser) {
            const fetchMessages = async () => {
                try {
                    const response = await axios.get(`http://mariabird.net:65535/api/dms/${selectedUser}`);
                    setMessages(response.data);
                } catch (error) {
                    console.error('Error fetching messages:', error);
                }
            };

            fetchMessages();
        }
    }, [selectedUser]);

    if (loading) {
        return <Spinner size="xl" />;
    }

    return (
        <Box display="flex">
            <UserList users={users} onSelectUser={setSelectedUser} />
            <Messages selectedUser={selectedUser} messages={messages} />
        </Box>
    );
}

export default DMApp;
