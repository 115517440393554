import React, { useEffect, useState } from 'react';
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  IconButton,
  Button
} from "@chakra-ui/react";
import { EditIcon, AddIcon } from "@chakra-ui/icons";
import axios from 'axios';
import { useAuth } from 'context/AuthContext'; // Assurez-vous que le chemin est correct
import { roles } from 'context/Permissions/RolesPermissions';
import CreateUserModal from './create';
import EditUserModal from './edit';

export default function UsersList() {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://westnine.api.mrapi.net/allusers', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [token]);

  const getGradeName = (grade) => {
    const role = Object.keys(roles).find(roleKey => roles[roleKey].grade === grade);
    return role ? role : "Inconnu";
  };

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <Flex justifyContent="flex-end" mb={4}>
        <Button colorScheme="blue" onClick={() => setCreateModalOpen(true)} leftIcon={<AddIcon />}>
          Créer un Utilisateur
        </Button>
      </Flex>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Discord ID</Th>
                <Th>Prénom</Th>
                <Th>Nom de famille</Th>
                <Th>Grade</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.id}</Td>
                  <Td>{user.name}</Td>
                  <Td>{user.firstname}</Td>
                  <Td>{user.lastname}</Td>
                  <Td>{getGradeName(user.grade)}</Td>
                  <Td>
                    <IconButton
                      icon={<EditIcon />}
                      onClick={() => {
                        setSelectedUserId(user.id);
                        setEditModalOpen(true);
                      }}
                      aria-label="Edit"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <CreateUserModal isOpen={isCreateModalOpen} onClose={() => setCreateModalOpen(false)} />
      <EditUserModal isOpen={isEditModalOpen} onClose={() => setEditModalOpen(false)} userId={selectedUserId} />
    </Flex>
  );
}
