import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box, Text, Flex, Image, useColorModeValue } from '@chakra-ui/react';
import axios from 'axios';
import Argent from "assets/imgdrop/money.png";
import Passport from "assets/imgdrop/passport.png";
import Medaille from "assets/imgdrop/medaille.png";
import './styles.css'; // Assurez-vous d'importer les styles CSS si nécessaire

const MiniStatistics = ({ title, amount, icon, isPulsing }) => {
  const iconBoxInside = useColorModeValue('white', 'gray.800');

  // Style conditionnel pour le halo rouge avec pulsation
  const boxShadow = isPulsing ? '0 0 15px rgba(255, 0, 0, 0.7)' : 'none';
  const animationClass = isPulsing ? 'pulsate' : '';

  return (
    <Box
      p={4}
      bg={useColorModeValue('white', 'gray.700')}
      shadow='md'
      borderRadius='lg'
      boxShadow={boxShadow}
      className={animationClass} // Appliquer la classe CSS conditionnelle
    >
      <Flex justify='space-between' align='center'>
        <Box>
          <Text fontSize='lg' fontWeight='bold'>{title}</Text>
          <Text fontSize='2xl' fontWeight='bold'>{amount}</Text>
        </Box>
        <Box bg={iconBoxInside} borderRadius='full' p={2}>
          <Image src={icon} h="24px" w="24px" />
        </Box>
      </Flex>
    </Box>
  );
};

const Dashboard = () => {
  const [data, setData] = useState({
    argentPropre: 0,
    argentSale: 0,
    totalArgent: 0,
    nbPersonnePF: 0,
    nbpoint: '0'  // Valeur par défaut
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupération des données depuis l'API de l'argent
        const moneyResponse = await axios.get('https://westnine.api.mrapi.net/api/money');
        const moneyItems = moneyResponse.data;

        // Calcul des sommes
        const argentPropre = moneyItems
          .filter(item => item.type === 'money')
          .reduce((total, item) => total + item.stock, 0);

        const argentSale = moneyItems
          .filter(item => item.type === 'dirty_money')
          .reduce((total, item) => total + item.stock, 0);

        const totalArgent = argentPropre + argentSale;

        // Récupération des données depuis l'API de l'organisation
        const orgResponse = await axios.get('https://westnine.api.mrapi.net/api/Orga');
        const orgItems = orgResponse.data;

        // Affichage des données pour la première organisation
        if (orgItems.length > 0) {
          const org = orgItems[0];
          setData({ 
            argentPropre, 
            argentSale, 
            totalArgent,
            nbPersonnePF: org.membre,
            nbpoint: `#${org.classement} - ${org.pts} Pts`
          });
        }
      } catch (error) {
        console.error('Il y a eu une erreur lors de la récupération des données !', error);
      }
    };

    fetchData();
  }, []);

  // Extraire les points de data.nbpoint et vérifier si cela est inférieur ou égal à 1000
  const points = data.nbpoint.split(' ')[1];
  const isPulsing = points ? parseInt(points) <= 1000 : false; // Correction ici

  return (
    <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>
      <MiniStatistics
        title={"Argent Propre"}
        amount={`${data.argentPropre}$`}
        icon={Argent}
      />
      <MiniStatistics
        title={"Argent Sale"}
        amount={`${data.argentSale}$`}
        icon={Argent}
      />
      <MiniStatistics
        title={"NB Personne PF"}
        amount={data.nbPersonnePF}
        icon={Passport}
      />
      <MiniStatistics
        title={"Classement PF"}
        amount={data.nbpoint}
        icon={Medaille}
        isPulsing={isPulsing} // Passer le drapeau de pulsation
      />
    </SimpleGrid>
  );
};

export default Dashboard;
