// Chakra imports
import {
    Flex,
    Grid,
    Box,
    Image,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react";

import StatsArgent from "./components/StatsArgent";
import React from "react";

import CalculetteArgentSale from "./components/CalculetteArgentSale";
import CalculatriceAS from "./components/CalculatriceAS";
import UserOnlineServer from "./components/UserOnlineServer";
  
  export default function Dashboard() {
    const iconBoxInside = useColorModeValue("white", "white");
  
    return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <StatsArgent />
      <Flex>
        <Box flex="1" mr={1}>
          <CalculetteArgentSale />
          <CalculatriceAS />
        </Box>
        <Box flex="0 0 auto">
          <UserOnlineServer />
        </Box>
      </Flex>
    </Flex>
  );
}