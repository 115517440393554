import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useHistory } from 'react-router-dom'; // Pour react-router-dom v5
import {
    Box,
    Flex,
    Image,
    SimpleGrid,
    Text,
    useColorModeValue,
    Button,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const QuotaPage = () => {
    const [quotas, setQuotas] = useState([]);
    const [error, setError] = useState(null);
    const history = useHistory(); // Utilisation de useHistory ici

    useEffect(() => {
        const fetchQuotas = async () => {
            try {
                const response = await axios.get('/api/quotas', { withCredentials: true });
                setQuotas(response.data);
            } catch (err) {
                setError('Erreur lors de la récupération des quotas');
            }
        };

        fetchQuotas();
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <h1>Page de Quota</h1>
            <table>
                <thead>
                    <tr>
                        <th>Nom d'utilisateur</th>
                        <th>Quota</th>
                    </tr>
                </thead>
                <tbody>
                    {quotas.map((quota) => (
                        <tr key={quota.id}>
                            <td>{quota.username}</td>
                            <td>{quota.quota}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default QuotaPage;
