import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

function Messages({ selectedUser, messages }) {
    return (
        <Box w="75%" p={4}>
            <Heading mb={4}>{selectedUser ? `Messages with User ${selectedUser}` : 'Select a user'}</Heading>
            {messages.length === 0 ? (
                <Text>No messages found.</Text>
            ) : (
                <VStack spacing={4} align="start">
                    {messages.map((msg) => (
                        <Box key={msg.id} p={4} shadow="md" borderWidth="1px" borderRadius="md">
                            <Text fontWeight="bold">{msg.username} ({msg.user_id})</Text>
                            <Text>{msg.message_content}</Text>
                            <Text color="gray.500" fontSize="sm">{new Date(msg.timestamp).toLocaleString()}</Text>
                        </Box>
                    ))}
                </VStack>
            )}
        </Box>
    );
}

export default Messages;
