import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useAuth } from 'context/AuthContext';
import {
    Box,
    Flex,
    Image,
    SimpleGrid,
    Text,
    Button,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    useDisclosure,
    useToast,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useColorModeValue
} from "@chakra-ui/react";
import { Link, useLocation } from 'react-router-dom';
import { EditIcon } from "@chakra-ui/icons";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function Coffre() {
    const { token, userDetails } = useAuth();
    const [items, setItems] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [newStock, setNewStock] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const iconBoxInside = useColorModeValue("white", "white");
    const location = useLocation();

    const tabIndex = location.pathname === "/Entrepot/AG/Coffre" ? 1 : 0;
    const authorizedUsers = ["mrkey2b", "babycarpe", "tetsuya3828"];

    const formatDate = (date) => {
        return format(new Date(date), "dd/MM/yyyy 'à' HH'h'mm", { locale: fr });
    };

    useEffect(() => {
        const fetchItems = () => {
            axios.get('https://westnine.api.mrapi.net/api/coffre/AG/items', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    setItems(response.data);
                    setLastUpdate(new Date());
                })
                .catch(error => {
                    console.error("There was an error fetching the items!", error);
                });
        };

        fetchItems();
        const intervalId = setInterval(fetchItems, 10000);

        return () => clearInterval(intervalId);
    }, [token]);

    const getImageUrl = (itemName) => {
        try {
            return require(`./img/${itemName.replace(/\s+/g, '').toLowerCase()}.png`);
        } catch (err) {
            return require(`./img/default.png`);
        }
    };

    const handleUpdateStock = async () => {
        const currentItem = items.find(item => item.id === selectedItem.id);
    
        const webhookData = {
            itemName: selectedItem.name,
            quantityBefore: currentItem.stock,
            quantityAfter: newStock,
            modifiedBy: `${userDetails?.firstname || 'Inconnu'} ${userDetails?.lastname || ''}`,
            modificationDateTime: new Date().toISOString(),
            imageUrl: getImageUrl(selectedItem.name)
        };
    
        let updateData = {};
        if (selectedItem.type === 'money') {
            updateData = { Quantité_AP: newStock, stock: 0 };
        } else if (selectedItem.type === 'dirty_money') {
            updateData = { Quantité_AS: newStock, stock: 0 };
        } else {
            updateData = { stock: newStock };
        }
    
        try {
            await axios.put(
                `https://westnine.api.mrapi.net/api/coffre/AG/items/${selectedItem.id}`, 
                updateData, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
    
            await sendToWebhook(webhookData);
    
            setItems(items.map(item => (item.id === selectedItem.id ? { ...item, stock: newStock } : item)));
            setIsUpdating(false);
            setNewStock('');
            onClose();
        } catch (error) {
            console.error("There was an error updating the item!", error);
            toast({
                title: "Erreur de mise à jour",
                description: error.response?.data || "Une erreur est survenue lors de la mise à jour de l'item.",
                status: "error",
                duration: 10000,
                isClosable: true,
                position: "bottom-right",
                containerStyle: {
                    bottom: '20px',
                    right: '20px'
                }
            });
        }
    };
    
    const sendToWebhook = async (data) => {
        const webhookUrl = 'https://discord.com/api/webhooks/1253027525345677363/ygzmKtc_reAPKM7iP_NKBguTpR0XnUJ1SkamBS7S4dc65pRoTH5W0dqDGzs3UhNPy0a9';
        const payload = {
            embeds: [
                {
                    title: 'Coffre Entrepot - 1Tonne (Tablette)',
                    fields: [
                        {
                            name: 'Nom de l\'item',
                            value: data.itemName,
                            inline: true
                        },
                        {
                            name: 'Quantité Avant',
                            value: data.quantityBefore.toString(),
                            inline: true
                        },
                        {
                            name: 'Quantité Après',
                            value: data.quantityAfter.toString(),
                            inline: true
                        },
                        {
                            name: 'Modifié par',
                            value: data.modifiedBy || 'Inconnu',
                            inline: true
                        },
                        {
                            name: 'Date & Heure de Modification',
                            value: formatDate(data.modificationDateTime),
                            inline: true
                        },
                    ],
                    timestamp: new Date().toISOString(),
                    color: 5814783
                }
            ]
        };
    
        try {
            await axios.post(webhookUrl, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log("Données envoyées au webhook avec succès.");
        } catch (error) {
            console.error("Erreur lors de l'envoi des données au webhook", error);
        }
    };

    return (
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
            <Flex justifyContent="flex-start" alignItems="center" mb="20px">
                <Tabs 
                    variant="solid-rounded" 
                    colorScheme="red" 
                    index={tabIndex} // Définit l'index actif en fonction de l'URL
                >
                    <TabList>
                        <Tab 
                            as={Link} 
                            to="/Entrepot/Coffre"
                            _selected={{ bg: "red.600", color: "white" }} 
                            _hover={{ bg: "red.400" }}
                            borderRadius="md"
                            fontWeight="bold"
                        >
                            Coffre WN
                        </Tab>
                        {authorizedUsers.includes(userDetails?.name) && (
                            <Tab 
                                as={Link} 
                                to="/Entrepot/AG/Coffre"
                                _selected={{ bg: "red.600", color: "white" }} 
                                _hover={{ bg: "red.400" }}
                                borderRadius="md"
                                fontWeight="bold"
                            >
                                Coffre AG
                            </Tab>
                        )}
                    </TabList>
                </Tabs>
                {lastUpdate && (
                    <Text fontSize="sm" color="gray.500" ml="auto">
                        Mise à jour à : {lastUpdate.toLocaleTimeString()}
                    </Text>
                )}
            </Flex>
            
            <SimpleGrid columns={{ base: 2, md: 5 }} spacing='10px'>
                {items.map((item) => (
                    <Box
                        key={item.id}
                        borderWidth='1px'
                        borderRadius='lg'
                        overflow='hidden'
                        boxShadow='sm'
                        width='150px'
                        position='relative'
                        bg={item.stock === 0 ? 'red.900' : 'transparent'}
                    >
                        {userDetails?.grade >= 85 && (
                            <Button
                                position='absolute'
                                top='10px'
                                left='10px'
                                size='sm'
                                zIndex='10'
                                onClick={() => {
                                    setSelectedItem(item);
                                    onOpen();
                                }}
                                aria-label="Modifier l'item"
                            >
                                <EditIcon />
                            </Button>
                        )}

                        <Image src={getImageUrl(item.name)} alt={item.name} boxSize='150px' />
                        <Box
                            position='absolute'
                            top='0'
                            right='0'
                            bg='red.500'
                            color='white'
                            px='2'
                            py='1'
                            borderRadius='0 0 0 5px'
                        >
                            <Text fontSize='md' fontWeight='bold'>
                                {item.type === "money" || item.type === "dirty_money" ? `${item.stock}$` : `x${item.stock}`}
                            </Text>
                        </Box>
                        <Box
                            position='absolute'
                            bottom='0'
                            left='50%'
                            transform='translateX(-50%)'
                            bg='rgba(0, 0, 0, 0.6)'
                            color='white'
                            width='100%'
                            textAlign='center'
                            py='1'
                        >
                            <Text fontSize='sm' fontWeight='bold'>
                                {item.name}
                            </Text>
                        </Box>
                    </Box>
                ))}
            </SimpleGrid>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modifier le stock de l'item</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Nom de l'item</FormLabel>
                            <Input isReadOnly value={selectedItem?.name || ''} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Stock</FormLabel>
                            <Input
                                type='number'
                                value={newStock}
                                onChange={(e) => setNewStock(e.target.value)}
                            />
                        </FormControl>
                        <Flex mt={4} justifyContent='flex-end'>
                            <Button colorScheme='blue' onClick={handleUpdateStock}>
                                Enregistrer
                            </Button>
                            <Button ml={3} onClick={onClose}>
                                Annuler
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
}
