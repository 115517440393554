import React from 'react';
import { Box, Heading, List, ListItem, Button } from '@chakra-ui/react';

function UserList({ users, onSelectUser }) {
    return (
        <Box w="25%" p={4} borderRightWidth="1px">
            <Heading mb={4}>Users</Heading>
            <List spacing={3}>
                {users.map((user) => (
                    <ListItem key={user.id}>
                        <Button
                            variant="ghost"
                            w="full"
                            onClick={() => onSelectUser(user.id)}
                        >
                            {user.username}
                        </Button>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default UserList;
