import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  Container,
  useToast,
  Textarea,
  Flex,
  Avatar,
  Image
} from '@chakra-ui/react';

function DiscordAdmin() {
    const [status, setStatus] = useState('dnd');
    const [activityName, setActivityName] = useState('');
    const [activityType, setActivityType] = useState('Watching');
    const [servers, setServers] = useState([]);
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState('');
    const [channelId, setChannelId] = useState('');
    const [userId, setUserId] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [titleDM, setTitleDM] = useState('');
    const [descriptionDM, setDescriptionDM] = useState('');
    const [color, setColor] = useState('#00FF00');
    const [botAvatar, setBotAvatar] = useState('');
    const [botStatus, setBotStatus] = useState('');
    const [botActivity, setBotActivity] = useState('');
    const [botActivityType, setBotActivityType] = useState('');
    const toast = useToast();

    const badgeIcons = {

      4194304: require('./badges/22.png'),
      64: require('./badges/6.png'),
      128: require('./badges/7.png'),
      256: require('./badges/8.png'),
  };
  
    const badgeMap = {
      1: "Discord Employee",
      2: "Discord Partner",
      4: "HypeSquad Events",
      8: "Bug Hunter Level 1",
      16: "MFA SMS",  // Not public
      32: "Nitro Promo Dismissed",  // Not public
      64: "House Bravery",
      128: "House Brilliance",
      256: "House Balance",
      512: "Early Supporter",
      1024: "Team User",
      2048: "Internal Application",  // Not public
      4096: "System User",
      8192: "Has Unread Urgent Messages",  // Not public
      16384: "Bug Hunter Level 2",
      32768: "Underage Deleted",  // Not public
      65536: "Verified Bot",
      131072: "Early Verified Bot Developer",
      262144: "Moderator Programs Alumni",
      524288: "Bot with Interactions Endpoint",
      1048576: "Spammer",  // Not public
      2097152: "Disable Nitro Features",  // Not public
      4194304: "Active Developer",
      // The rest are not public or are not user-related flags
      8589934592: "High Global Rate Limit",  // Not public
      17179869184: "Deleted",  // Not public
      34359738368: "Disabled Suspicious Activity",  // Not public
      68719476736: "Self Deleted",  // Not public
      137438953472: "Premium Discriminator",  // Not public
      274877906944: "Used Desktop Client",  // Not public
      549755813888: "Used Web Client",  // Not public
      1099511627776: "Used Mobile Client",  // Not public
      2199023255552: "Disabled",  // Not public
      8796093022208: "Verified Email",  // Not public
      17592186044416: "Quarantined",  // Not public
      1125899906842624: "Collaborator",  // Not public
      2251799813685248: "Restricted Collaborator",  // Not public
  };
  
  const getBadgeIcons = (flags) => {
    let icons = [];
    for (let flagValue in badgeIcons) {
        if ((flags & flagValue) === parseInt(flagValue)) {
            icons.push(badgeIcons[flagValue]);
        }
    }
    return icons;
};


  useEffect(() => {
    const fetchBotInfo = async () => {
      try {

        const response = await axios.get('https://wn.bot.mrapi.net/api/bot-info');
        const data = response.data;
        setBotAvatar(data.avatar);
        setBotStatus(data.status);
        setBotActivity(data.activity);
      } catch (error) {
        console.error('Error fetching bot info:', error);
        toast({
          title: 'Failed to load bot info.',
          description: 'Could not fetch bot information.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchBotInfo();
  }, [toast]);

  useEffect(() => {
    const fetchServerInfo = async () => {
      try {
        const response = await axios.get('https://wn.bot.mrapi.net/api/server-info');
        setServers(response.data);
      } catch (error) {
        console.error('Error fetching server info:', error);
        toast({
          title: 'Failed to load server info.',
          description: 'Could not fetch server information.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchServerInfo();
  }, [toast]);

  const updateActivity = async () => {
    try {
      await axios.post('https://wn.bot.mrapi.net/api/change-activity', {
        status,
        activityName,
        activityType,
      });
      toast({
        title: 'Activity updated.',
        description: 'Your bot activity has been updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating activity:', error);
      toast({
        title: 'Failed to update activity.',
        description: 'There was an error updating the bot activity.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const sendDM = async () => {
    if (!userId || !titleDM || !descriptionDM) {
      toast({
        title: 'Missing parameters',
        description: 'Please ensure all fields are filled out correctly.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post('https://wn.bot.mrapi.net/api/send-dm', {
        userId,
        titleDM,
        descriptionDM,
        color,
      });
      toast({
        title: 'Message sent.',
        description: 'Your message has been sent successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Failed to send message.',
        description: 'There was an error sending the message.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const loadChannels = async () => {
      try {
        const response = await axios.get('https://wn.bot.mrapi.net/api/channels'); // Appel à l'API backend
        setChannels(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des canaux:', error);
      }
    };

    loadChannels();
  }, []);

  const sendMessage = async () => {
    if (!channelId || !title || !description) {
      toast({
        title: 'Missing parameters',
        description: 'Please ensure all fields are filled out correctly.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post('https://wn.bot.mrapi.net/api/send-message', {
        channelId,
        title,
        description,
        color,
      });
      toast({
        title: 'Message sent.',
        description: 'Your message has been sent successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Failed to send message.',
        description: 'There was an error sending the message.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="full" mt="10" p="5">
      <Flex direction="row" justify="space-between">
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="6" boxShadow="lg" width="30%">
          <Text fontSize="2xl" mb="4">Bot Administration</Text>
          <Flex direction="column" align="center" mb="6">
            <Avatar src={botAvatar} size="xl" mb="4" />
            <Text fontSize="lg" fontWeight="bold">{botStatus}</Text>
            <Text fontSize="md" color="gray.600">{botActivityType} {botActivity}</Text>
          </Flex>
          <Stack spacing="4" mb="6">
            <FormControl id="status">
              <FormLabel>Status</FormLabel>
              <Select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="online">Online</option>
                <option value="idle">Idle</option>
                <option value="dnd">Do Not Disturb</option>
                <option value="invisible">Invisible</option>
              </Select>
            </FormControl>

            <FormControl id="activityName">
              <FormLabel>Activity Name</FormLabel>
              <Input value={activityName} onChange={(e) => setActivityName(e.target.value)} />
            </FormControl>

            <FormControl id="activityType">
              <FormLabel>Activity Type</FormLabel>
              <Select value={activityType} onChange={(e) => setActivityType(e.target.value)}>
                <option value="Playing">Playing</option>
                <option value="Streaming">Streaming</option>
                <option value="Listening">Listening</option>
                <option value="Watching">Watching</option>
                <option value="Competing">Competing</option>
              </Select>
            </FormControl>

            <Button colorScheme="blue" onClick={updateActivity} width="full">
              Update Activity
            </Button>
          </Stack>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="6" boxShadow="lg" width="30%">
      <Text fontSize="xl" mb="4">Send a Message</Text>
      
      <FormControl id="channelId" mt="4">
        <FormLabel>Channel</FormLabel>
        <Select
          value={selectedChannel}
          onChange={(e) => setSelectedChannel(e.target.value)}
          placeholder="Select a channel"
        >
          {channels.map((channel) => (
            <option key={channel.id} value={channel.id}>
              {channel.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl id="title" mt="4">
        <FormLabel>Title</FormLabel>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter the title of the embed"
        />
      </FormControl>

      <FormControl id="description" mt="4">
        <FormLabel>Description</FormLabel>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter the description of the embed"
        />
      </FormControl>

      <FormControl id="color" mt="4">
        <FormLabel>Color</FormLabel>
        <Input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
      </FormControl>

      <Button colorScheme="green" onClick={sendMessage} width="full" mt="4">
        Send Message
      </Button>
    </Box>

        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="6" boxShadow="lg" width="30%">
          <Text fontSize="xl" mb="4">Send a DM Message</Text>
          <FormControl id="userId">
            <FormLabel>User ID</FormLabel>
            <Input
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="Enter User ID"
            />
          </FormControl>

          <FormControl id="titleDM" mt="4">
            <FormLabel>Title</FormLabel>
            <Input
              value={titleDM}
              onChange={(e) => setTitleDM(e.target.value)}
              placeholder="Enter the title of the embed"
            />
          </FormControl>

          <FormControl id="descriptionDM" mt="4">
            <FormLabel>Description</FormLabel>
            <Textarea
              value={descriptionDM}
              onChange={(e) => setDescriptionDM(e.target.value)}
              placeholder="Enter the description of the embed"
            />
          </FormControl>

          <FormControl id="color" mt="4">
            <FormLabel>Color</FormLabel>
            <Input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </FormControl>

          <Button colorScheme="green" onClick={sendDM} width="full" mt="4">
            Send DM Message
          </Button>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="6" boxShadow="lg" width="30%">
    <Text fontSize="xl" mb="4">Server Info</Text>
    {servers.map((server, index) => (
        <Box key={index} mb="4">
            <Text fontSize="lg" fontWeight="bold">{server.name}</Text>
            <Text fontSize="sm">Members: {server.memberCount}</Text>
            <Stack spacing="2" mt="2">
                {server.members.map((member, memberIndex) => (
                    <Box key={memberIndex} p="2" borderWidth="1px" borderRadius="md" boxShadow="sm">
                        <Text fontWeight="bold">{member.name} - ({member.id})</Text>
                        <Flex>
                        {getBadgeIcons(member.flags).map((icon, iconIndex) => (
                          <Image 
                            key={iconIndex} 
                            src={icon} 
                            alt="Badge Icon" 
                            boxSize="20px" 
                            mr="2"
                          />
                        ))}
                        </Flex>
                        <Text>Roles: {member.roles.join(', ')}</Text>
                    </Box>
                ))}
            </Stack>
        </Box>
    ))}
</Box>

      </Flex>
    </Container>
  );
}

export default DiscordAdmin;
