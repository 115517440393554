// routes.js
import Dashboard1 from "views/Dashboard/Dashboard/index2.js";
import CoffreEntrepot from "views/Dashboard/Entrepot";
import Tables from "views/Dashboard/Tables";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

//Custom
import UsersList from "views/Admin/Users/list";
import EditUser from "views/Admin/Users/edit";
import CreateUser from "views/Admin/Users/create";
import ArgentPerUser from "views/Dashboard/Comptabilite/argentperusers";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  RocketIcon,
} from "components/Icons/Icons";
import { FaDiscord } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineInventory2 } from "react-icons/md";
import QuotaPage from "views/Dashboard/Works/qoata";
import DiscordApp from "views/Admin/Discord";
import DMList from "views/Admin/Discord/DmMessage";
import CoffreAG from "views/Dashboard/Entrepot/indexAG";

// Define routes with requiredGrades and hidden properties
const dashRoutes = [
  {
    path: "/Admin/Discord/Application/1268248192844042261",
    name: "DiscordBot - WestNine",
    icon: <FaDiscord color="inherit" />,
    component: DiscordApp,
    layout: "",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/Admin/Discord/Application/dm/list",
    name: "DiscordBot - WestNine DM",
    icon: <FaDiscord color="inherit" />,
    component: DMList,
    layout: "",
    requiredGrades: [255],
    hidden: true,
  },
  {
    path: "/DashBoard",
    name: "Dashboard",
    icon: <MdOutlineDashboard color="inherit" />,
    component: Dashboard1,
    layout: "",
    requiredGrades: [0, 40, 50, 85, 90, 94, 95, 100, 255],
    hidden: false,
  },
  {
    path: "/Entrepot/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreEntrepot,
    layout: "",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: false,
  },
  {
    path: "/Entrepot/AG/Coffre",
    name: "Coffre Entrepot - AG",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreAG,
    layout: "",
    requiredGrades: [255],
    hidden: true,
  },
  {
    path: "/Works/Quota",
    name: "Works",
    icon: <HomeIcon color="inherit" />,
    component: QuotaPage,
    layout: "",
    requiredGrades: [255], 
    hidden: true,
  },
  {
    path: "/Orga/WN/Users",
    name: "Users WN",
    icon: <FaRegUser color="inherit" />,
    component: ArgentPerUser,
    layout: "",
    requiredGrades: [94, 95, 100, 255],
    hidden: false,
  },
  {
    path: "/Users",
    name: "Users Administration",
    icon: <FaRegUser color="inherit" />,
    component: UsersList,
    layout: "",
    requiredGrades: [94, 95, 100, 255],
    hidden: false,
  },
  {
    path: "/Users/Create",
    name: "Create User",
    component: CreateUser,
    layout: "",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/Users/Edit/:id",
    name: "Edit User",
    component: EditUser,
    layout: "",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: true,
    component: Profile,
    layout: "",
    hidden: true,
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
  },
  {
    path: "/login",
    component: SignIn,
    layout: "/auth",
    hidden: true,
  },
  {
    path: "/register",
    name: "Sign Up",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    hidden: true,
  },
];

export default dashRoutes;
