import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, HStack, Circle } from '@chakra-ui/react';

const UserOnlineServer = () => {
  const [client, setClient] = useState('');
  const [players, setPlayers] = useState([]);

  const namesToFilter = ['MrKey2B', 'bidouille9122', 'ZyanFR ッ', 'tetsuya ', 'babycarpe', 'billonbierry'];
  const nameMappings = {
    'MrKey2B': 'Anthony Ghais',
    'bidouille9122': 'Angel Waston',
    'ZyanFR ッ': 'Zyan McCall',
    'tetsuya ': 'Renaldo Silva',
    'babycarpe': 'Bob Delawid',
    'billonbierry': 'Levi Tortor'
  };

  useEffect(() => {
    const fetchServerData = async () => {
      try {
        const response = await fetch('https://servers-frontend.fivem.net/api/servers/single/k4aqg7');
        const data = await response.json();

        // Récupérer le client
        const client = data.Data.clients;

        // Récupérer les joueurs avec id et name et filtrer pour n'afficher que les noms dans namesToFilter
        const filteredPlayers = data.Data.players
          .filter(player => namesToFilter.includes(player.name))
          .map(player => ({
            id: player.id,
            name: player.name,
            fullName: nameMappings[player.name]
          }));

        // Mettre à jour l'état avec les données récupérées si elles ont changé
        setClient(client);
        setPlayers(prevPlayers => {
          const havePlayersChanged = JSON.stringify(prevPlayers) !== JSON.stringify(filteredPlayers);
          if (havePlayersChanged) {
            return filteredPlayers;
          }
          return prevPlayers;
        });

      } catch (error) {
        console.error('Error fetching server data:', error);
      }
    };

    // Fetch initial data
    fetchServerData();

    // Set interval to fetch data every 10 seconds
    const intervalId = setInterval(fetchServerData, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
      <Box p={2}>
        <VStack spacing={2}>
        <Text fontSize="2xl" mb={2}>Server Players: {client}</Text>
        <Text fontSize="xl" mb={2}>Players Online</Text>
          {players.map(player => (
            <Box 
              key={player.id} 
              p={5} 
              shadow="md" 
              borderWidth="1px" 
              borderRadius="md" 
              w="100%"
            >
              <HStack>
                <Circle size="10px" bg="green.500" />
                <Text>{player.name} (ID: {player.id}) - {player.fullName}</Text>
              </HStack>
            </Box>
          ))}
        </VStack>
      </Box>
  );
};

export default UserOnlineServer;
